import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';

const DocumentFaq = ({ 
  document, 
  setNewQuestion, 
  submitQuestionButtonRef }) => {
  const [showAllFaqs, setShowAllFaqs] = useState(false);
  const parentDivRef = useRef(null); // Reference to the parent div


  if (document.faqs === null || document.faqs === undefined) {
    return <></>;
  }
  const faqs = document.faqs;
  const faqArray = faqs.split('\n');

  if (faqArray.length === 0) {
    return <></>;
  }

  // show only the first 5 FAQS by default
  let displayedFaqs = faqArray.slice(0, 5); // Show only the first 5 FAQs by default
  if (showAllFaqs) {
    displayedFaqs = faqArray; // Show all FAQs if 'showAllFaqs' is true
  }

  const handleShowMoreClick = () => {
    setShowAllFaqs(true);
  };

  const handleShowLessClick = () => {
    setShowAllFaqs(false);
  };

  const scrollToTopOfParentDiv = () => {
    let parent = parentDivRef.current;
    while (parent && parent.scrollTop === 0) {
      parent = parent.parentNode;
    }
    if (parent) {
      parent.scrollTop = 0;
    }
  };

  // // shorten the list of questions if there are too many
  // if (faqArray.length > 9) {
  //   faqArray.length = 9;
  // }

  const handleButtonClick = async (question, setNewQuestion, submitQuestionButtonRef) => {
    console.log('button clicked: ' + question);
    await setNewQuestion(question);
    console.log('new question: ' + question);
    submitQuestionButtonRef.current.click();
    console.log('submitted: ' + question);
    scrollToTopOfParentDiv(); // Scroll to top of the parent div when "Show Less" is clicked
  };

  return (
  <div style={{textAlign: 'left'}} ref={parentDivRef}>
      <ul>
        {displayedFaqs.map((question, index) => (
          <li key={index} className="text-left">
            <Button 
              variant="outline-primary"
              className='text-left'
              onClick={() => {
                handleButtonClick(question, setNewQuestion, submitQuestionButtonRef);
              }}
            >
              {question}
            </Button>
          </li>
        ))}
      </ul>
      {faqArray.length > 5 && (
        <div>
          {!showAllFaqs && (
            <Button variant="primary" onClick={handleShowMoreClick}>
              Show More
            </Button>
          )}
          {showAllFaqs && (
            <Button variant="secondary" onClick={handleShowLessClick}>
              Show Less
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default DocumentFaq;