import { useEffect, useState } from "react";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import DisplaySources from "./DisplaySources";
import { updateQuestionRating } from "../api";
import CopyButton from "./CopyButton";
import ReactMarkdown from 'react-markdown';

const QuestionItem = ({ question, setQuestions, questions, languageModels, 
                        setSearchKeyword, showLeftColumn, setShowLeftColumn,
                        extractionMethod}) => {
    const [rating, setRating] = useState(0);
    const [upClicked, setUpClicked] = useState(false);
    const [downClicked, setDownClicked] = useState(false);
    const [showTooltipUp, setShowTooltipUp] = useState(false);
    const [showTooltipDown, setShowTooltipDown] = useState(false);

    useEffect(() => {
        setRating(question.rating || 0);
        setUpClicked(question.rating === 1);
        setDownClicked(question.rating === -1);
    }, [question]);
  
    const handleUpClick = async () => {
      if (!upClicked) {
        setRating(1);
        setUpClicked(true);
        setDownClicked(false);
        // update the rating in the react questions state
        const newQuestions = questions.map((q) => {
          if (q.question_id === question.question_id) {
            return { ...q, rating: 1 };
          }
          return q;
        });
        setQuestions(newQuestions);


        // Send update to backend database with rating = 1
        // Send update to backend database with rating = 1
        try {
            await updateQuestionRating(question.question_id, 1);
        } catch (error) {
            console.log(error);
        }
      }
    };
  
    const handleDownClick = async () => {
      if (!downClicked) {
        setRating(-1);
        setUpClicked(false);
        setDownClicked(true);

        // update the rating in the react questions state
        const newQuestions = questions.map((q) => {
          if (q.question_id === question.question_id) {
            return { ...q, rating: -1 };
          }
          return q;
        });
        setQuestions(newQuestions);

        // Send update to backend database with rating = -1
        try {
            await updateQuestionRating(question.question_id, -1);
        } catch (error) {
            console.log(error);
        }
      }
    };

    const tooltipUp = (
      <Tooltip id="copy-tooltip" show={showTooltipUp}>
        Good Answer!
      </Tooltip>
    );

    const tooltipDown = (
      <Tooltip id="copy-tooltip" show={showTooltipUp}>
        Bad Answer!
      </Tooltip>
    );
    

  return (
    <ListGroup.Item 
      key={question.question_id}
      className="text-left"
      >
      <div className="d-flex align-items-center justify-content-between">
      <h5 
        className="text-left">
          {question.question}
          <CopyButton text={question.question + '\n' + question.answer} />
      </h5>
      <div className="d-flex justify-content-end">
          {/* Feedback Buttons */}
            <OverlayTrigger placement="top" overlay={tooltipUp}>
              <button
                className={`btn btn-link btn-light ${upClicked ? "text-primary" : "text-secondary"}`}
                onClick={handleUpClick}
              >
                {/* <i className="bi bi-hand-thumbs-up"></i> {rating === 1 && "(+1)"} */}
                <FaThumbsUp className={upClicked ? "text-primary" : ""} />
              </button>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={tooltipDown}>
              <button
                className={`btn btn-link btn-light ${downClicked ? "text-" : "text-secondary"}`}
                onClick={handleDownClick}
              >
                {/* <i className="bi bi-hand-thumbs-down"></i>{" "}
                {rating === -1 && "(-1)"} */}
                <FaThumbsDown className={downClicked ? "text-danger" : ""} />
              </button>
            </OverlayTrigger>
            </div>
          </div>
      <div>
          <div className="bullet-list">
            {/* <strong>Answer:</strong> */}
            {/* {" "} */}
            <ReactMarkdown>{question.answer}</ReactMarkdown>
          </div>          
      </div>
      <p style={{textAlign: 'center'}}>
      {question.sources && question.sources !== "" && (
        <DisplaySources 
          sources={question.sources} 
          setSearchKeyword={setSearchKeyword} 
          showLeftColumn={showLeftColumn}
          setShowLeftColumn={setShowLeftColumn}
          extractionMethod={extractionMethod}
          />
      )}
      </p>
    </ListGroup.Item>
  );
};

export default QuestionItem;
