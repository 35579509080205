
import App from './App';
import LandingPage from './components/LandingPage';
import SigninContainer from './components/SigninContainer';
import SignupContainer from './components/SignupContainer';

import Pricing from './components/landingpage/pages/Pricing';
import TermsConditions from './components/TermsConditions';
import Security from './components/Security';
import PrivacyContainer from './components/PrivacyContainer';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

function AppMain() {
    return (
        <Router>
        <Routes>
            <Route path="/app" element={<App />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/signin" element={<Navigate to="/app" /> }/>
            {/* <Route path="/signin" element={<SigninContainer/> }/> */}
            <Route path="/signup" element={<SignupContainer />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/security" element={<Security />} />
            <Route path="/privacy" element={<PrivacyContainer />} />
        </Routes>
        </Router>
    );
    }

export default AppMain;