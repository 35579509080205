import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Privacy from './landingpage/pages/Privacy';

function PrivacyContainer() {
    return (
      <>
      <Privacy />
      {/* <h1>SigninContainer</h1> */}
      </>
    );
  }
  
  export default PrivacyContainer;