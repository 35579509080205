import { useState, useEffect } from 'react';
import { login } from './api';
import { Container, Button, Form } from 'react-bootstrap';
// import { useLocation } from 'react-router-dom';

export const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [signinError, setSigninError] = useState('');
  // used to handle signup success
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const success = queryParams.get('success');
  // const { state } = useLocation();
  const [signupSuccess, setSignupSuccess] = useState(false);
  useEffect(() => {
    const success = localStorage.getItem('signupSuccess');
    if (success) {
      setSignupSuccess(true);
      localStorage.removeItem('signupSuccess');
    }
    // const success2 = localStorage.getItem('signinError');
    // if (success2) {
    //   setSigninError(true);
    //   localStorage.removeItem('signinError');
    // }
  }, []);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const userData = { email, password };
      const data = await login(userData);
      console.log(data);
      console.log(data.error);
      console.log('data');
      const accessToken = data.access;
      onLogin(accessToken);
      if (data.error) {
        setSigninError(true);
      }
    
    } catch (err) {
      console.log('her');
      console.log(err);
      setError(err.message);  
    }
  };

  return (
    
    <Container className="mt-5">
      {signupSuccess && (
        <div className="max-w-md mx-auto bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">Signup <strong>successful</strong>. Please sign in with your new account.</span>
        </div>
      )}

      {signinError && (
        <div className="max-w-md mx-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">Signup <strong>unsuccessful</strong>. Please try again. Make sure to use a valid email address.</span>
        </div>
      )}

      <div className="d-flex justify-content-center">
      <Form onSubmit={handleSubmit} style={{ width: '30rem' }}>
        <Form.Group controlId="formBasicUsername">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </Form.Group>
        <br></br>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </div>
    </Container>
  );
};