import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import { isAuthenticated, logout } from '../../../auth';
import { Button } from 'react-bootstrap';
import {getAccessToken, getUser} from '../../../api';
import { URL } from '../../../constants';
import PricingTableComponent from './PricingTableComponent';
import { DEBUG } from '../../../constants';

function PricingTables() {

  const [value, setValue] = useState(true);
  const [user, setUser] = useState(null);

  const [priceOutput] = useState({
    plan1: {
      true: ['$', '0', '/month'],
      false: ['$', '0', '/year']
    },
    plan2: {
      true: ['$', '30', '/month'],
      false: ['$', '300', '/year']
    },
    plan3: {
      true: ['$', '50', '/month'],
      false: ['$', '500', '/year']
    },
    plan4: {
      true: ['$', '150', '/month'],
      false: ['$', '1500', '/year']
    },
    plan5: {
      true: ['', '', ''],
      false: ['', '', '']
    }
  });
  var buttonLabels = isAuthenticated()
  ? ['Try for Free', 'Upgrade', 'Upgrade', 'Upgrade', 'Contact Us']
  :['Try for Free', 'Start free trial', 'Start free trial', 'Start free trial', 
    'Start free trial'];

  var buttonLinks = isAuthenticated()
  ? ['/app', '/app', '/app', '/app', '/app']
  :['/signup', '/signup', '/signup', '/signup', '/signup'];

  const temp_pricingIdsAll = DEBUG ? {
    plan2: {
      true: 'price_1Mzn70GfbWY5P4rMI4I8pVDo', // $30/month
      false: 'price_1MznDiGfbWY5P4rMPqjxoaaT', // $300/year
    },
    plan3: {
      true: 'price_1Mzn7MGfbWY5P4rMMT1vNnwD', // $50/month
      false: 'price_1MznF3GfbWY5P4rM6uTX1g73', // $500/year
    },
    plan4: {
      true: 'price_1Mzn7jGfbWY5P4rMiD0fYwby', // $150/month
      false: 'price_1MznFTGfbWY5P4rMhpJjoQh9',
    }
  } : {
    // these are the real pricing ids
    plan2: {
      true: 'price_1N0wA9GfbWY5P4rMuvVG7EOg', // $30/month
      false: 'price_1N0wA9GfbWY5P4rMeKTGkF0B', // $300/year
    },
    plan3: {
      true: 'price_1N0wAZGfbWY5P4rMoMzQAczu', // $50/month
      false: 'price_1N0wAZGfbWY5P4rMPoYdvqRS', // $500/year
    },
    plan4: {
      true: 'price_1N0wAeGfbWY5P4rMkENDAL4i', // $150/month
      false: 'price_1N0wAeGfbWY5P4rMJcpxffwC',
    }
  };
  
  const [pricingIdsAll] = useState(temp_pricingIdsAll);

  // var pricingIds = ['price_1Mzn70GfbWY5P4rMI4I8pVDo', // $30/month
  //                   'price_1Mzn7MGfbWY5P4rMMT1vNnwD', // $50/month
  //                   'price_1Mzn7jGfbWY5P4rMiD0fYwby'] // $150/month
  // var pricingIdsYearly = ['price_1MznDiGfbWY5P4rMPqjxoaaT', // $300/year
  //                         'price_1MznF3GfbWY5P4rM6uTX1g73', // $500/year
  //                         'price_1MznFTGfbWY5P4rMhpJjoQh9'] // $1500/year
  
  const updateAllGets = async () => {
    const accessToken = await getAccessToken();

    const userData = await getUser(accessToken);
    if (userData) {
      setUser(userData);
      console.log('user', user);
    }
  };  
  useEffect(() => {
    updateAllGets();
    document.title = 'Talk To Files';
  }, []);                     
  
  const createCheckoutSession = async (pricingId) => {

    try {
      console.log('create checkout session');
      const accessToken = await getAccessToken();
      console.log(accessToken);
      const response = await fetch(URL + 'api/create-checkout-session/',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          pricing_id: pricingId,
        })
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };                          
  
  return (
    <section className="bg-gradient-to-b from-white to-gray-100">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-20 md:pb-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-4">
            <h1 style={{ fontSize: '3.25rem', fontWeight: '800', fontFamily: 'ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"' }} className="h1 mb-4" data-aos="zoom-y-out">Start for free. Pay as you grow. Switch at any time.</h1>
            {/* <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">No matter how many team members you have - our pricing is simple, transparent and adapts to the size of your company.</p> */}
          </div>

          {/* Pricing tables */}
          <div>

            {/* Pricing toggle */}
            {/* <div className="flex justify-center max-w-xs m-auto mb-16 pb-8" data-aos="zoom-y-out" data-aos-delay="300">
              <div className="relative flex w-full mx-6 p-1 bg-gray-200 rounded">
                <span
                  className="absolute inset-0 m-1 pointer-events-none"
                  aria-hidden="true"
                >
                  <span className={`absolute inset-0 w-1/2 bg-white rounded shadow transform transition duration-150 ease-in-out ${value ? 'translate-x-0' : 'translate-x-full'}`}></span>
                </span>
                <button
                  className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${value && 'text-gray-500'}`}
                  onClick={(e) => { e.preventDefault(); setValue(true); }}
                >Bill Monthly</button>
                <button
                  className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${!value && 'text-gray-500'}`}
                  onClick={(e) => { e.preventDefault(); setValue(false); }}
                >Bill Yearly <span className="text-green-500">-17%</span>
                </button>
                
              </div>
            </div> */}

            <div className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-2 xl:grid-cols-5 xl:gap-6 items-start">

            <PricingTableComponent
              user={user}
              value={value}
              buttonLabel={buttonLabels[0]}
              buttonLink={buttonLinks[0]}
              title="Free"
              priceOutputPlan={priceOutput.plan1}
              pricingId={null}
              isLinkToPayment={false}
              offer1="3 documents"
              offer2 = "10 questions"
              offer3 = "50 pages / doc"
              shortText = "Try it out."
              />

            <PricingTableComponent
              user={user}
              value={value}
              buttonLabel={buttonLabels[1]}
              buttonLink={buttonLinks[1]}
              title="Intro"
              priceOutputPlan={priceOutput.plan2}
              pricingId={pricingIdsAll.plan2[value]}
              isLinkToPayment={user?.[0]?.subscription_status !== 'active'}
              offer1="20 documents"
              offer2 = "100 questions"
              offer3 = "100 pages / doc"
              shortText = "Get started."
              />

            <PricingTableComponent
              user={user}
              value={value}
              buttonLabel={buttonLabels[2]}
              buttonLink={buttonLinks[2]}
              title="Standard"
              priceOutputPlan={priceOutput.plan3}
              pricingId={pricingIdsAll.plan3[value]}
              isLinkToPayment={user?.[0]?.subscription_status !== 'active'}
              offer1="40 documents"
              offer2 = "200 questions"
              offer3 = "200 pages / doc"
              shortText = "Get serious."
              addStar={true}
              highlighted={true}
              />

            <PricingTableComponent
              user={user}
              value={value}
              buttonLabel={buttonLabels[3]}
              buttonLink={buttonLinks[3]}
              title="Bulk"
              priceOutputPlan={priceOutput.plan4}
              pricingId={pricingIdsAll.plan4[value]}
              isLinkToPayment={user?.[0]?.subscription_status !== 'active'}
              offer1="100 documents"
              offer2 = "500 questions"
              offer3 = "200 pages / doc"
              shortText = "Go big."
              addStar={false}
              highlighted={false}
              />

              {/* Pricing table 1 */}
              {/* <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
                <div className="mb-4">
                  <div className="text-lg font-bold mb-1">Free</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-3xl font-bold">{priceOutput.plan1[value][0]}</span>
                    <span className="text-4xl font-bold">{priceOutput.plan1[value][1]}</span>
                    <span className="text-gray-600 pl-2">{priceOutput.plan1[value][2]}</span>
                  </div>
                  <div className="text-lg text-gray-800">Try it out.</div>
                </div>
                <ul className="text-gray-600 -mb-2 grow">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>3 documents</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>10 questions</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>50 pages / doc</span>
                  </li>
                </ul>
                <div className="border-t border-gray-200 pt-5 mt-6">
                  <a className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full btn btn-primary" 
                  href={buttonLinks[0]}>{buttonLabels[0]}</a>
                  <form action="http://localhost:8000/api/create-checkout-session/" method="POST">
                    <input type="hidden" name="priceId" value={pricingIds[1]} />
                    {user? (<input type="hidden" name="email" value={user[0].email} />) : (null)}
                    <button type="submit">Checkout</button>
                  </form>

                </div>
              </div> */}

              {/* Pricing table 2 */}
              {/* <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
                <div className="mb-4">
                  <div className="text-lg font-bold mb-1">Intro</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-3xl font-bold">{priceOutput.plan2[value][0]}</span>
                    <span className="text-4xl font-bold">{priceOutput.plan2[value][1]}</span>
                    <span className="text-gray-600 pl-2">{priceOutput.plan2[value][2]}</span>
                  </div>
                  <div className="text-lg text-gray-800">Get started.</div>
                </div>
                <ul className="text-gray-600 -mb-2 grow">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>20 documents</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>100 questions</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>100 pages / doc</span>
                  </li>
                </ul>
                <div className="border-t border-gray-200 pt-5 mt-6">
                  <a className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full btn btn-primary" 
                  href={buttonLinks[1]}>{buttonLabels[1]}</a>
                </div>
              </div> */}

              {/* Pricing table 3 */}
              {/* <div className="relative flex flex-col h-full py-5 px-6 rounded bg-blue-100 shadow-xl border-2 border-blue-500" data-aos="zoom-y-out" data-aos-delay="450">
              
                <div className="absolute top-5 right-0 mr-5 p-3 -mt-5 bg-yellow-500 rounded-full">
                  
                  <span className="bg-yellow-500 rounded-full p-1">
                    <FaStar color="white"/>
                  </span>
                </div>
                <div className="mb-4">
                  <div className="text-lg font-bold mb-1">Standard</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-3xl font-bold">{priceOutput.plan3[value][0]}</span>
                    <span className="text-4xl font-bold">{priceOutput.plan3[value][1]}</span>
                    <span className="text-gray-600 pl-2">{priceOutput.plan3[value][2]}</span>
                  </div>
                  <div className="text-lg text-gray-800">Get serious.</div>
                </div>
                <ul className="text-gray-600 -mb-2 grow">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>40 documents</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>1000 questions</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>200 pages / doc</span>
                  </li>
                </ul>
                <div className="border-t border-gray-200 pt-5 mt-6">
                  <a className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full btn btn-primary" 
                  href={buttonLinks[2]}>{buttonLabels[2]}</a>
                </div>
              </div> */}

              {/* Pricing table 4 */}
              {/* <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
                <div className="mb-4">
                  <div className="text-lg font-bold mb-1">Bulk</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-3xl font-bold">{priceOutput.plan4[value][0]}</span>
                    <span className="text-4xl font-bold">{priceOutput.plan4[value][1]}</span>
                    <span className="text-gray-600 pl-2">{priceOutput.plan4[value][2]}</span>
                  </div>
                  <div className="text-lg text-gray-800">Go big.</div>
                </div>
                <ul className="text-gray-600 -mb-2 grow">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>150 documents</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>3000 questions</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>500 pages / doc</span>
                  </li>
                </ul>
                <div className="border-t border-gray-200 pt-5 mt-6">
                  <a className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full btn btn-primary" 
                  href={buttonLinks[3]}>{buttonLabels[3]}</a>
                </div>
              </div> */}

              {/* Pricing table 5 */}
              <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
                <div className="mb-4">
                  <div className="text-lg font-bold mb-1">Enterprise</div>
                  <div className="inline-flex items-baseline mb-2">
                    
                    <span className="text-3xl font-bold">{priceOutput.plan5[value][0]}</span>
                    <span className="text-4xl font-bold">{priceOutput.plan5[value][1]}</span>
                    <span className="text-4xl font-bold"><br></br></span>
                    <span className="text-gray-600 pl-2">{priceOutput.plan5[value][2]}</span>
                    
                    <br></br>
                  </div>
                  <div className="text-lg text-gray-800">Contact us.</div>
                </div>
                <ul className="text-gray-600 -mb-2 grow">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Unlimited documents</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Unlimited questions</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Unlimited pages</span>
                  </li>
                </ul>
                <div className="border-t border-gray-200 pt-5 mt-6">
                  <a className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full btn btn-primary" 
                  href="mailto:sales@talktofiles.com">{buttonLabels[4]}</a>
                </div>
              </div>

            </div>

          </div>

        </div >
      </div >
    </section >
  );
}

export default PricingTables;
