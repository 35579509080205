import { FaStar } from 'react-icons/fa';
import { URL } from '../../../constants';

const PricingTableComponent = ({ 
    user, 
    value,
    buttonLink,
    buttonLabel,
    title,
    priceOutputPlan,
    pricingId,
    isLinkToPayment,
    offer1,
    offer2,
    offer3,
    shortText,
    addStar,
    highlighted }) => {

        console.log('isLinkToPayment', isLinkToPayment)

    const divClassName = highlighted? "relative flex flex-col h-full py-5 px-6 rounded bg-blue-100 shadow-xl border-2 border-blue-500" : "relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl"

    return(
<div className={divClassName} data-aos="zoom-y-out" data-aos-delay="450">
    {addStar? (
        <>
        <div className="absolute top-5 right-0 mr-5 p-3 -mt-5 bg-yellow-500 rounded-full">
                  {/* <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">Popular</span> */}
                  {/* <svg className="w-4 h-4 fill-current text-white" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.145 5.05l-4.316-.627L8.898.513c-.338-.684-1.456-.684-1.794 0l-1.93 3.91-4.317.627a1.002 1.002 0 00-.554 1.707l3.124 3.044-.737 4.3a1 1 0 001.45 1.053L8 13.125l3.862 2.03c.728.381 1.59-.234 1.45-1.054l-.736-4.299L15.7 6.758a1.003 1.003 0 00-.555-1.708z" />
                  </svg> */}
                  <span className="bg-yellow-500 rounded-full p-1">
                    <FaStar color="white"/>
                  </span>
                </div>
        </>
    ) : (null)}
    <div className="mb-4">
        <div className="text-lg font-bold mb-1">{title}</div>
        <div className="inline-flex items-baseline mb-2">
        <span className="text-3xl font-bold">{priceOutputPlan[value][0]}</span>
        <span className="text-4xl font-bold">{priceOutputPlan[value][1]}</span>
        <span className="text-gray-600 pl-2">{priceOutputPlan[value][2]}</span>
        </div>
        <div className="text-lg text-gray-800">{shortText}</div>
    </div>
    <ul className="text-gray-600 -mb-2 grow">
        <li className="flex items-center mb-2">
        <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
        </svg>
        <span>{offer1}</span>
        </li>
        <li className="flex items-center mb-2">
        <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
        </svg>
        <span>{offer2}</span>
        </li>
        <li className="flex items-center mb-2">
        <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
        </svg>
        <span>{offer3}</span>
        </li>
    </ul>
    <div className="border-t border-gray-200 pt-5 mt-6">
        {isLinkToPayment? (
        <><form action={`${URL}api/create-checkout-session/`} method="POST">
        <input type="hidden" name="priceId" value={pricingId} />
        {user? (<input type="hidden" name="email" value={user[0].email} />) : (null)}
        <button className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full btn btn-primary" 
                type="submit">{buttonLabel}</button>
        </form></>
        ) : (
            <a className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full btn btn-primary" 
                href={buttonLink}>{buttonLabel}</a>
        )}

        {/* <Button onClick={() => createCheckoutSession(pricingIds[1])}>
        Test
        </Button> */}
    </div>
</div>
    )
}

export default PricingTableComponent;