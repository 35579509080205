import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SignUp from './landingpage/pages/SignUp';


function SignupContainer() {
  return (
    
        <>
        <SignUp />
        </>
      );
    }      
  
  export default SignupContainer;