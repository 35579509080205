import {useState } from "react";
import { FaCopy } from 'react-icons/fa';
// import { BsClipboard } from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from "react-bootstrap";


function CopyButton({ text }) {
    const [copied, setCopied] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
  
    const handleClick = async () => {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setShowTooltip(true);
      setTimeout(() => {
        setCopied(false);
        setShowTooltip(false);
      }, 1000);
    };

    const tooltip = (
      <Tooltip id="copy-tooltip" show={showTooltip}>
        Copy
      </Tooltip>
    );
  
    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <button
          onClick={handleClick}

          // className={`btn btn-sm btn-outline-dark ${copied ? 'bg-success border-success' : 'border-light'}`}
          // >
          className={`btn btn-light ${copied ? 'bg-success border-success' : 'text-secondary border-light'}`}
          >

          {/* {copied ? 'Copied!' : 'Copy'} */}
          <FaCopy />
        </button>
      </OverlayTrigger>
    );
  }

export default CopyButton;