import { Button, Modal } from 'react-bootstrap';
import SecurityContent from './landingpage/partials/SecurityContent';

const SecurityFAQModal = ({ showModal, handleCloseModal }) => {
    return (
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Security FAQs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <SecurityContent/>
          <h3></h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

export default SecurityFAQModal;