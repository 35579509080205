import React, { useState, useEffect } from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';

function DocumentSelector({ documents, selectedDocumentId, handleSelectChange }) {
  return (
    <div className="my-list-container">
      <h3>Select Document:</h3>
      <div className="scrollable-container" style={{textAlign: 'left'}}>
        <ListGroup className="">
          {documents.map((document) => (
            <ListGroup.Item
              key={document.document_id}
              active={selectedDocumentId === document.document_id}
              onClick={() => handleSelectChange(document.document_id)}
              style={document.status === 'pending' ? { backgroundColor: 'green', color: 'white', border: '1px solid green' } : {}}
            >
              {document.status === 'pending' ? (
                <>
                  <Spinner animation="border" size="md" className="mr-2"/>
                  <span style={{ fontWeight: "bold"}}>{document.document_name}</span>
                </>
              ) : (
                document.document_name
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    </div>
  );
}

export default DocumentSelector;
