import React, { useEffect } from 'react';

function VidyardPlayer(props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://play.vidyard.com/embed/v4.js';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <img
      alt='vidyard player'
      style={{ width: '100%', margin: 'auto', display: 'block' }}
      className="vidyard-player-embed"
      src={`https://play.vidyard.com/${props.uuid}.jpg`}
      data-uuid={props.uuid}
      data-v="4"
      data-type="inline"
    />
  );
}

export default VidyardPlayer;
