import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { URL } from './constants';

// const BASE_URL = 'http://localhost:8000/api/';
const BASE_URL = URL + 'api/';

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 300000, // 5 minutes
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': Cookies.get('csrftoken'),
  },
});

export const register = async (userData) => {
  const response = await api.post('register/', userData);
  return response.data;
};

export const login = async (userData) => {
  const response = await api.post('login/', userData);
  const { access, refresh } = response.data;
  console.log(response.data);
  console.log(response);

  if (access && refresh){
    Cookies.set('access_token', access, { sameSite: 'strict', secure: true });
    Cookies.set('refresh_token', refresh, { sameSite: 'strict', secure: true });
    // refresh the page to clear the cache
    window.location.reload();
  } else {
    console.log('no access or refresh token; login failed')
  };


  return response.data;
};

export const logout = () => {
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
};

export const getAccessToken = () => {
  const token = Cookies.get('access_token');
//   return token ? jwt_decode(token) : null;
  return token
};

export const refreshAccessToken = async () => {
    const refreshToken = Cookies.get('refresh_token');
  
    if (refreshToken) {
      const response = await api.post('auth/refresh/', { refresh: refreshToken });
      const { access } = response.data;
      Cookies.set('access_token', access, { sameSite: 'strict', secure: true });
      return access;
    }
  
    return null;
  };
export const getQuestionsReturn = async (accessToken) => {
  const response = await axios.get(BASE_URL + 'questions/', {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};


export const getQuestions = async (accessToken, setQuestions) => {
    const response = await axios.get(BASE_URL + 'questions/', {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    setQuestions(response.data);
  };

export const getDocuments = async (accessToken, setDocuments) => {
  const response = await axios.get(BASE_URL + 'documents/', {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  // setDocuments(response.data);
  return response.data;
};

export const getUser = async (accessToken) => {
  const response = await axios.get(BASE_URL + 'users/', {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};

export const postDocument = async (accessToken, file, documentType) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('document_type', documentType);
  
  const response = await api.post(BASE_URL + 'document_add/', formData, {
    headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
};

export const updateQuestionRating = async (questionId, rating) => {
  const accessToken = await getAccessToken();
  
  const response = await api.patch(
    `${BASE_URL}questions/${questionId}/`,
    { rating },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response.data;
};

export const deleteDocument = async (documentId) => {
  try {
    const accessToken = await getAccessToken();
    const response = await api.post(
      `${BASE_URL}document_delete/`,
      { document_id: documentId },
      { 
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting document:", error);
    // throw new Error("Failed to delete document");
  }
};

  
// export const refreshAccessToken = async () => {
//   const refreshToken = Cookies.get('refresh_token');

//   if (refreshToken) {
//     const response = await api.post('auth/refresh/', { refresh: refreshToken });
//     const { access } = response.data;
//     Cookies.set('access_token', access, { sameSite: 'strict', secure: true });
//     return access;
//   }

//   return null;
// };

export default api;
