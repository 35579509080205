import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const currentYear = new Date().getFullYear(); // Get current year

function Footer() {
    return (
      <footer className="mt-auto" style={{ marginTop: "auto", width: "100%" }}>
        <Container>
          <Row>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <p><br></br>
                © {currentYear} TalkToFiles, LLC</p>
            </Col>
            {/* <Col className="d-flex justify-content-center">
              <p>Privacy Policy</p>
            </Col>
            <Col className="d-flex justify-content-center">
              <p>Terms of Use</p>
            </Col> */}
          </Row>
        </Container>
      </footer>
    );
  }
  
  export default Footer;