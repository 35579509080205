import React, { useState, useEffect } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';

function DisplaySources(props) {
  const sources = props.sources;
  const setSearchKeyword = props.setSearchKeyword;
  const showLeftColumn = props.showLeftColumn;
  const setShowLeftColumn = props.setShowLeftColumn;
  const extractionMethod = props.extractionMethod;
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  }

  const handleSourceClick = (source) => {
    const firstFourWords = source.split(" ").slice(0, 4).join(" ");
    // setSearchKeyword(""); // clear the search keyword
    setSearchKeyword(firstFourWords);
    }


  return (
    <div class="accordion" >
        <div class="card">
            <div class="card-header py-0">
                <button class="btn btn-link" type="button" onClick={toggleAccordion}>See References:</button>
                {isOpen && (
                    <div>
                        <p style={{fontSize: "14px"}}>
                            {sources.map((source, index) => (
                                extractionMethod === 'textract' ? (
                                    <p key={index}> <strong>source {(index + 1) + ": "}</strong>{source}</p>
                                ) : (
                                    <button 
                                    onClick={() => {
                                        setShowLeftColumn(false);
                                        handleSourceClick(source)} 
                                    }
                                    className="btn btn-link"
                                    style={{ backgroundColor: "white", color: "#212529"}}

                                    >
                                    <p style={{fontSize: "14px"}} key={index}> <strong>source {(index + 1) + ": "}</strong>{source}</p>
                                </button>
                                )
                            ))}
                        </p>
                    </div>
                )}
            </div>
        </div>
    </div>
  );
}

export default DisplaySources;

    // <div class="accordion" id="accordionExample">
    //     <div class="card">
    //         <div class="card-header" id="headingOne">
    //         <h5 class="mb-0">
    //             <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
    //             Collapsible Group Item #1
    //             </button>
    //         </h5>
    //         </div>

    //         <div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
    //         <div class="card-body">
                // <div>
                // <p>Sources:</p>
                // <ul>
                //     {sources.map((source, index) => (
                //     <li key={index}>{"source " + (index + 1) + ": " + source}</li>
                //     ))}
                // </ul>
                // </div>
    //         </div>
    //         </div>
    //     </div>
    // </div> 

        // <div>
    //   <p>Sources:</p>
    //   <ul>
    //     {sources.map((source, index) => (
    //       <li key={index}>{"source " + (index + 1) + ": " + source}</li>
    //     ))}
    //   </ul>
    // </div>

    // <div>
    //   <p>Sources:</p>
    //   <Accordion>
    //     {sources.map((source, index) => (
    //       <Card key={index}>
    //         <Card.Header>
    //           <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
    //             {"source " + (index + 1)}
    //           </Accordion.Toggle>
    //         </Card.Header>
    //         <Accordion.Collapse eventKey={index.toString()}>
    //           <Card.Body>
    //             {source}
    //           </Card.Body>
    //         </Accordion.Collapse>
    //       </Card>
    //     ))}
    //   </Accordion>
    // </div>