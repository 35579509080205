import React, { useState } from "react";
import { Accordion, Button, Modal } from "react-bootstrap";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { getAccessToken, getQuestions } from "../api";
import { URL } from "../constants";
import { BsFillTrash3Fill, BsBoxArrowDown } from "react-icons/bs";

const ChatHeader = ({ selectedDocumentName, document, setQuestions }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    }

    // Function to copy text to the clipboard
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(
        () => {
          console.log('Text copied to clipboard');
        },
        (err) => {
          console.error('Failed to copy: ', err);
        }
      );
    };


    const handleDeleteDocument = async (document, setQuestions) => {
      // e.preventDefault();
      // setIsSubmitting(true);
      const accessToken = await getAccessToken();
      // console.log('document_id: ', document.document_id);
      try {
        
        const response = await fetch(URL + 'api/document_delete/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            document_id: document.document_id,
            // user: jwt_decode(accessToken).user_id,
          })
        });
        const data = await response.json();
        // console.log(data);
    
      } catch (error) {
        console.error(error);
      } finally {
        getQuestions(accessToken, setQuestions);
        // refresh the page to clear the cache
        window.location.reload();
      }
    };

    const handleCloseModal = () => {
      setShowModal(false);
    };
  
    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleCloseModal2 = () => {
      setShowModal2(false);
    };
  
    const handleShowModal2 = () => {
      setShowModal2(true);
    };

    // console.log(document);
    return (
   <div class="accordion" style={{ margin: '0 auto', maxWidth: '80%' }}>
  <div class="card" style={{ border: 'none' }}>
    <div class="card-header py-0" style={{ backgroundColor: 'white' }}>
      <h4>
        
        <button class="btn btn-link" type="button" onClick={toggleAccordion}
        >
          <h4 class="my-0">
          <span class="document-name">
            { selectedDocumentName && selectedDocumentName.length > 80 ? selectedDocumentName.slice(0, 80) + '...' : selectedDocumentName }
          </span>
            {isOpen ? <FaCaretUp /> : <FaCaretDown />}
          </h4>
        </button>
        <Button variant="danger" 
            onClick={handleShowModal}><BsFillTrash3Fill/>
          </Button>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            This document and all related questions will be removed from our database. This cannot be reversed. 
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button variant="danger" 
                onClick={() => handleDeleteDocument(document, setQuestions)}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <Button variant="primary" 
            onClick={handleShowModal2}><BsBoxArrowDown />
          </Button>
          <Modal show={showModal2} onHide={handleCloseModal2}>
            <Modal.Header closeButton>
              <Modal.Title>Text Content</Modal.Title>
              <Button variant="secondary" onClick={handleCloseModal2}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => copyToClipboard(document.text)}>
                Copy Text
              </Button>
            </Modal.Header>
            <Modal.Body>
            <p>{document && <p>{document.text}</p>}</p>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
      </h4>
      {isOpen && (
        <div style={{ backgroundColor: '#F2F2F2', padding: '10px', borderRadius: '5px', textAlign: 'center', border: '1px solid rgba(0, 0, 0, 0.125)'}}>
          <p style={{ margin: '0' }}>
            Text Length: <strong>{document.text_length}</strong> characters;
            # Chunks: <strong>{document.num_chunks ? document.num_chunks : 0}</strong>;
            Min chunk size: <strong>{document.chunk_min_length ? document.chunk_min_length : 0}</strong>;
            Max chunk size: <strong>{document.chunk_max_length ? document.chunk_max_length : 0}</strong>;
            Conversion method: <strong>{document.extraction_method ? document.extraction_method : null }</strong>;
            Document Type: <strong>{document.document_type ? document.document_type : null}</strong>
          </p>
        </div>
      )}
    </div>
  </div>
</div>
    );
};

export default ChatHeader;
