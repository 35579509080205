import React from 'react';
import Accordion from '../utils/Accordion';
import DeleteDocumentImage from '../images/delete-document.png';

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2" style={{ fontSize: '3.25rem', fontWeight: '800', fontFamily: 'ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"' }}>
              Frequently Asked Questions
            </h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            <Accordion title="How secure is my data?" active>
              <p>We use industry-standard encryption to protect your data. We also use a secure, encrypted connection to transfer data to and from our servers. When you upload a file, our server converts the file into machine-readable text. We store this text in an encrypted database. We do not store the original file, unless you request us to. We do not share any of your data with third parties.</p>
              <p>
              When you delete a document, we remove all of the processed text from the database, and we remove the original file (if you previously asked us to retain it). You can also delete all question-and-answer sessions associated with a document.
              </p>
              <p>
              See more details on the <a className="text-blue-600 hover:underline" href="/security">security page</a>.
              </p>

            </Accordion>
            <Accordion title="What file types are supported?">
              We support most major file types (and we're adding more soon!), including PDFs, MS Word (.docx and .doc) and text files.
            </Accordion>
            <Accordion title="Why doesn't it work on my file type?">
              If you're having trouble with a specific file, please contact us and we'll do our best to help.
            </Accordion>
            <Accordion title="How do I upload a file to the platform?">
              After logging in, simply click the "Upload" button in the bottom left corner of the screen. You can also drag and drop files into the upload area.
            </Accordion>
            <Accordion title="Can I delete my uploaded files? If so, how?">
              Yes, you can delete your uploaded files by: 
              <ul className="list-decimal pl-20">
              <li>Selecting a Document from the left menu;</li> 
              <li>Expanding the dropdown details for the document;</li> 
              <li>Clicking the "Delete" button.</li>
              </ul>
              This will remove the document from all of our servers. It cannot be recovered.
              <img className="mx-auto" src={DeleteDocumentImage} width="768" height="432" alt="How to delete an uploaded file by clicking on the Delete button" />
            </Accordion>
            <Accordion title="What happens if I accidentally upload a sensitive or confidential document?">
              If you accidentally upload a sensitive or confidential document, you can simply delete it. This will remove the document from all of our servers.
            </Accordion>
            <Accordion title="Is there a limit to the file size that can be uploaded?">
              Yes, the maximum file size in the free tier is 32MB. If you need to upload a larger file, please contact us and we'll do our best to help.
            </Accordion>
            <Accordion title="Is there a limit to how many files I can upload?">
              There is no limit in the paid plans. In the free plan, you can upload up to 5 files.
            </Accordion>
            <Accordion title="Is there a limit to how many pages I can upload in a PDF?">
              PDFs are limited to 200 pages in the free plan. In the paid plans, there is no limit.
            </Accordion>
            <Accordion title="Is there a limit to the number of questions I can ask?">
              There is no limit in the paid plans. In the free plan, you can ask up to 10 questions per file.
            </Accordion>
            <Accordion title="How long does it take to process a file?">
              The time it takes to process a file depends on the size of the file and the number of pages. It can take anywhere from a few seconds to a few minutes. You can upload multiple files at once, and they will be processed in the order they were uploaded.
            </Accordion>
            <Accordion title="How do I access the answers provided by ChatGPT?">
              After uploading a file, you can ask questions about the file by typing into the search bar at the top of the screen and pressing "Submit". You can also see your history of previous questions underneath the search bar.
            </Accordion>
            <Accordion title="How accurate are the answers provided by ChatGPT?">
              The accuracy of the answers provided by ChatGPT depends on the quality of the text extracted from the file. If the text is not clear, the answers may not be accurate. We are working on improving the accuracy of the answers provided by ChatGPT.
            </Accordion>
            <Accordion title="What if I don't understand the answers provided by ChatGPT?">
              If you don't understand the answers provided by ChatGPT, you can ask a follow-up question. You can also try a different language model. We currently support 3 language models: ChatGPT3, GPT-3, and GPT-4.
            </Accordion>
            <Accordion title="How can I get help if I have a problem using the platform?">
              If you have a problem using the platform, please contact us and we'll do our best to help.
            </Accordion>
            <Accordion title="Is there a way to provide feedback on the answers provided by ChatGPT?">
              Yes, you can provide feedback on the answers provided by ChatGPT by clicking the "Feedback" button at the bottom of the answer.
            </Accordion>
            {/* <Accordion title="How do I delete a file?">
              You can delete a file by clicking the "Delete" button next to the file in the "Documents" section.
            </Accordion> */}
            <Accordion title="Is there a way to see my past uploads and questions?">
              Yes, you can see your past uploads in the 
              "Documents" section. And your questions are available after you click on any Document.
            </Accordion>
            <span className="block border-t border-gray-200" aria-hidden="true"></span>
          </ul >

        </div >
      </div >
    </section >
  );
}

export default Faqs;
