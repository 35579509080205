// const DEBUG = false;
const DEBUG = (window.location.hostname === "localhost");

if (DEBUG) {
  console.log('DEBUG MODE');
  var URL = 'http://localhost:8000/';
} else {
  console.log('PRODUCTION MODE');
  // var URL = 'https://researchcompanion.onrender.com/';
  var URL = 'https://talktofiles.com/';
};

export { URL, DEBUG };