import React from 'react';

import DocsImage01 from '../images/docs-image-01.png';
import DocsImage02 from '../images/docs-image-02.png';

function SecurityContent() {
  return (
    <div className="md:grow">

      <div className="text-lg text-gray-600">
        <h2 style={{ fontSize: '3.25rem', fontWeight: '800', fontFamily: 'ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"' }} className="h2 text-gray-900 mb-4">Security</h2>
        <p className="mb-8">
       Last Updated: April 18, 2023
        </p>
        <p className="mb-8">
        <strong>Documents are confidential and secure.</strong> 
        </p>
        <p className="mb-8">
        We take security very seriously. We are committed to protecting your data and privacy.
        </p>
        
        <p className="mb-8">
        <strong>Uploading Documents.</strong> 
        </p>

        <p className="mb-8">
        When you upload a document, we try to parse it into machine-readable text. We use several processes to do this, including Optical Character Recognition (OCR), Natural Language Processing (NLP), and other proprietary processes. We may use a third-party service to do this. We currently only use Amazon Textract. If we add additional third-party document processing services, we will update this policy. We will never partner with a third party that retains your data. (See <a className="text-blue-600 hover:underline" href="https://docs.aws.amazon.com/textract/latest/dg/security.html">Amazon's Textract Security Policy</a> for more information.)
        </p>
        
        <p className="mb-8">
        <strong>Document Storage and Retention.</strong> 
        </p>

        <p className="mb-8">
        We then store the machine-readable text in a secure, encrypted database. We convert the text into a numerical representation, called an embedding. This embedding is also stored in the same secure database. 
        </p>

        <p className="mb-8">
        We retain the original document in an encrypted cloud storage (AWS) for the sole purpose of displaying the PDF to you in the PDF-viewer tool. You can delete your documents at any time by clicking the red "delete" button.
        </p>

        <p className="mb-8">

        <strong>Documents are encrypted in transit.</strong>
        </p>

        <p className="mb-8">
        We use HTTPS to encrypt all traffic between your browser and our servers.

        </p>
        

        <p className="flex items-center text-base px-3 py-4 text-gray-800 bg-blue-100 border border-blue-200 rounded mb-8">
          <svg className="w-4 h-4 fill-current text-blue-500 shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
          </svg>
          <span>Check out the <a className="text-blue-600 hover:underline" href="/terms">Terms and license page</a> to know more about our license.</span>
        </p>

      </div>

      {/* Related content */}
      <div className="mt-8">
        <h3 className="h3 mb-8">Related</h3>
        <a className="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="/privacy">
          <div>
            <div className="text-normal font-medium mb-1">Privacy Policy</div>
            <div className="text-sm text-gray-600">Learn more about our privacy policy.</div>
          </div>
          <svg className="w-4 h-4 fill-current text-blue-600 shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
          </svg>
        </a>
        <hr className="w-full h-px pt-px bg-gray-200 border-0 mt-8" />
      </div>
    </div>
  );
}

export default SecurityContent;