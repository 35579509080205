import React from 'react';
import { Container, Row, Col, Button, Card, Badge } from 'react-bootstrap';
import SignUp from './landingpage/pages/SignUp';
import AppHeader from './AppHeader';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { FaArrowLeft, FaStar } from 'react-icons/fa';
import {BsFillGearFill} from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { URL } from '../constants';
import moment from 'moment';

const Account = ({user, handleLogout, setShowAccountPage, showAccountPage}) => {

    const dateString = user[0].usage_month;
    const formattedDate = moment(dateString).format('MMMM YYYY');
 
    return (
        <>
        <Container fluid className="my-gradient-container py-4">
  <Card className="p-4 mx-auto" style={{ maxWidth: "500px" }}>
    <Button
        variant="dark"
      onClick={() => setShowAccountPage(prevState => !prevState)}
      className="mb-4"
    >
      <FaArrowLeft size={20}/> Return to App
    </Button>
    {/* <h1 className="mb-4">Account Details</h1> */}
    <h1 style={{ fontSize: '3.25rem', fontWeight: '800', fontFamily: 'ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"' }} className="h2 text-gray-900 mb-4">Account</h1>
    <div className='text-left'>
       <h5 className="mb-4">Email: {user[0].email}</h5>
       <Row>
            <Col>
                <h6 style={{ textDecoration: 'underline' }}>Month: {formattedDate}: </h6>
                <div>
                    <ul className="list-unstyled">
                        <li>Questions Asked: {user[0].usage_questions_asked} / {user[0].plan_question_limit}</li>
                        <li>Docs Uploaded: {user[0].usage_documents_uploaded} / {user[0].plan_document_limit}</li>
                        <li>Docs Deleted: {user[0].usage_documents_deleted}</li>
                    </ul>
                </div>
            </Col>
            <Col>
                <h6 style={{ textDecoration: 'underline' }}>All Time:</h6>
                <div>
                    <ul className="list-unstyled">
                        <li>Questions Asked: {user[0].questions_asked}</li>
                        <li>Docs Uploaded: {user[0].documents_uploaded}</li>
                        <li>Docs Deleted: {user[0].documents_deleted}</li>
                    </ul>
                </div>
            </Col>
        </Row>
    </div>
    <hr></hr>
    <div className='text-left'>
        <ul className="list-unstyled">
            <li>
                <span className="mb-4">Plan: </span>
                {user[0].subscription_status === 'active' ? (
                <>    
                    <span><strong>
                        {user[0].subscription_description}{'   '} 
                    </strong></span> 
                    <Link to="/pricing" className=''>
                        (Plan Details)
                    </Link>    
                </>
                ):(
                <span><strong>Free Plan</strong></span>
                )}
            </li>
            <li>
                <span className="mb-4">Subscription Status: </span>
                {user[0].subscription_status === 'active' ? (
                    <span className="badge bg-success text-white text-lg">Active</span>  
                ): (
                    <span className="badge bg-danger text-white text-lg">Inactive</span>
                )}
            </li>
            {user[0].is_premium ? (
                <li>
                    <span className="mb-4">Grandfathered: </span>
                    <span className="badge bg-success text-white text-lg">Active</span>
                </li>
            ) : (
                null
            )}
            <br></br>
            <li>
                <strong>Problems?</strong> Contact us at <a href="mailto:support@talktofiles.com">support@talktofiles.com</a>
            </li>
        
        </ul>
    </div>
    <hr></hr>
    <Card.Footer className="text-muted">
        {user[0].subscription_status === 'active' ? (
            <form action={`${URL}api/create-portal-session/`} method="POST">
            {user? (<input type="hidden" name="email" value={user[0].email} />) : (null)}
            <input type="hidden" name="email" value={user[0].email} />
            <button className="btn btn-dark" 
                    type="submit">Manage Subscription</button>
            </form>
        ) : (
        <Link to="/pricing" className='btn btn-primary'
            onClick={() => setShowAccountPage(prevState => !prevState)}
            >
            Upgrade Plan
        </Link>
        )}

    </Card.Footer>
  </Card>
</Container>
        </>
        );
        }      

    export default Account;