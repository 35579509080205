import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Form } from 'react-bootstrap';
import { URL } from '../../../constants';
import { useNavigate } from 'react-router-dom';

import Header from '../partials/Header';

function SignUp() {

  const [email, setEmail] = useState('');
  // const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { email, password };

    try {
      const response = await fetch(URL + 'api/register/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      
      if (!response.ok) {
        const json = await response.json();
        console.log(json);
        if (json.error) {
          setError(json.error);
        } else {
          setError('An error occurred while signing up. Please try again later.');
        }
      } else {
        setError('');
        // navigate('/signin?success=true', { state: { signupSuccess: true } });
        localStorage.setItem('signupSuccess', 'true');
        navigate('/signin?success=true', { state: { signupSuccess: true } });
      }
  
    } catch (err) {
      console.error('Error:', err);
      setError('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-2 flex flex-col items-center justify-center">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-2">
                <h1 style={{ fontSize: '3.25rem', fontWeight: '800', fontFamily: 'ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"' }} className="h1">Sign Up</h1>
              </div>
              {/* <div className="text-lg text-gray-600 md:grow">
              <p>
                We are currently in beta access only. Please check back shortly.
              </p>
              </div> */}

               {/* Signup Form */}

               <Container className="mt-5">
               {error && (

                    <div className="max-w-md mx-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <span className="block sm:inline">{error.email}</span>
                    {error.password && (
                    <span className="block sm:inline">Password cannot be blank</span>
                    )}
                  </div>
                      )}
                  
                <div className="d-flex justify-content-center">
                  <Form onSubmit={handleSubmit} style={{ width: '30rem' }} className="text-center">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      {/* {error && (
                        <p style={{ color: 'red' }}>{error.email}</p>
                      )} */}
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </Form.Group>

                    {/* <Form.Group controlId="formBasicUsername">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                      />
                    </Form.Group> */}

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      {/* {error.password && (
                        <p style={{ color: 'red' }}>{error.password}</p>
                      )} */}
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </Form.Group>
                    <br></br>
                    <Button variant="primary" type="submit">
                      Sign up
                    </Button>
                  </Form>
                </div>
              </Container>

              <div className="text-gray-600 text-center mt-6">
                  Already using TalkToFiles? <Link to="/signin" className="text-blue-600 hover:underline transition duration-150 ease-in-out">Sign in</Link>
               </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default SignUp;