import React from 'react';

// import '../style.css';

import Home from './landingpage/pages/Home';
function LandingPage() {
    return (
      <>
      <Home />
      </>
    );
  }
  
  export default LandingPage;

  //#212529