import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';

function UploadFileHeader() {
  return (
    <div className="upload-header">
      <h3>
        Upload Document:{" "}
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`tooltip-right`}>
              <p>Use PDFs for best results.<br></br>
                Max 64 MB.<br></br>
                Supports .pdf, .docx and .txt files.<br></br>
                Filenames must be unique.</p>
            </Tooltip>
          }
        >
          <span>
            <FaInfoCircle />
          </span>
        </OverlayTrigger>
      </h3>
    </div>
  );
}

export default UploadFileHeader;
