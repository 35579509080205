import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Terms from './landingpage/pages/Terms';

function TermsConditions() {
    return (
      <>
      <Terms />
      {/* <h1>SigninContainer</h1> */}
      </>
    );
  }
  
  export default TermsConditions;