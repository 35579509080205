import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SecurityContainer from './landingpage/pages/Security';

function Security() {
    return (
      <>
      <SecurityContainer />
      </>
    );
  }
  
  export default Security;