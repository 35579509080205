import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

export const isAuthenticated = () => {
  const token = Cookies.get('access_token');

  if (!token) {
    return false;
  }

  const decoded = jwt_decode(token);
  const exp = decoded.exp;
  const now = Date.now() / 1000;

  if (exp < now) {
    return false;
  }

  return true;
};

export const logout = () => {
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  // refresh the page to clear the cache
  window.location.reload();
};
